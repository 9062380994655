
.container {
    background-color: #d5dfe8;
    width: 100vw;
    height: 100vh;
    position: relative;
}

.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}