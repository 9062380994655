
  
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: #FF9F43 ;
    border: 1px solid #c57428;
}

.btn-primary-pd-light {
    padding: 0.175rem 0.55rem;
}

.btn-primary-wd {
    min-width: 120px;
}

.btn-primary:hover {
    background-color:    #1B2850;
}

.btn-submit,
.btn-cancel,
.btn-accept {
    min-width: 120px;
    color: #fff;
    font-weight: bold;
    padding: 12px 10px;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.btn-submit {
    background: #FF9F43;
    border: 1px solid #c57428;
}

.btn-submit:hover {
    background: #e2812b;
    color: #fff;
}

.btn-cancel {
    background: #637381;
    border: 1px solid #374047;
}


.btn-cancel:hover {
    background: #47525c;
    color: #fff;
}

.btn-accept {
    background:#1B2850;
}

.btn-accept:hover {
    background: #080c18;
    color: #fff;
}


.btn-gradient {
    background: linear-gradient( 90deg, #1b2950 0%, #1b2950 54.39%, #5870b2 122.86% );
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 12px 45px;
    font-weight: 600;
    font-size: 18px;
    color: #fff;

}

.btn-gradient:hover {
    background: linear-gradient(
      90deg,
      #fe9f43 0%,
      #fe9f43 54.39%,
      #f7e6d2 122.86%
    );
    color: #fff;
    transition: all ease-in-out 5s;
}

.fc-button {
    background: #f1f1f1;
    border: none;
    color: #797979;
    /* text-transform: capitalize; */
    box-shadow: none ;
    border-radius: 3px ;
    margin: 0 3px ;
    padding: 6px 12px;
    height: auto ;
}

.fc-button:hover {
    color: #464646;
    font-weight: bold;
}

.fc-button.nonehover:hover {
    color: #797979;
    font-weight: normal;
    background: #f1f1f1;
    cursor : default;
}




.pd-light {
    padding: 6px 0;
}

.wd-auto {
    min-width: auto;
}

.btn-blue {
    background: linear-gradient(to bottom, #4c9dff 2%,#187bf2 100%) !important; 
    color: #fff;
    font-weight: normal;
    padding: .3rem .4rem;
    border-radius: .2rem;
    height: 38px;
    min-width: 60px;
    font-size: 13px;
  
  }
  
  .btn-blue:hover {
    background: linear-gradient(to bottom, #458ee9 2%,#1561be 100%) !important;
  }
  

/* 
.btn_default:hover {
    filter: drop-shadow(-5px 15px 30px rgba(244,25,125,.35));
  }
   */