
input[type="radio"], input[type="checkbox"], select, button { cursor: pointer; }

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, textarea, p, blockquote, th, td, input, select, textarea, button { margin: 0; padding: 0; }

html { overflow-y: scroll; background: #fff; width: 100%; height: 100%; }

body { width: 100%; height: 100%; }

dl, ul, ol, menu, li { list-style: none; }

img, fieldset, iframe { border: 0 none; }

img { vertical-align: top; }

input, select, textarea, button { font: 12px/1.4 "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; vertical-align: middle; }

input::-ms-clear { display: none; }

button { border: 0 none; background-color: transparent; cursor: pointer; border-radius: 0; }

button:disabled { cursor: default; }

input[type="button"], input[type="submit"] { cursor: pointer; border: 0 none; }

table { border-collapse: collapse; border-spacing: 0; empty-cells: show; }

caption, legend { position: absolute; top: -9999px; left: -9999px; font-size: 0px; line-height: 0; }

a { color: #666; text-decoration: none; }

a:hover { text-decoration: underline; }

a:active { background-color: transparent; }

body, h1, h2, h3, h4, th, td, input, select, textarea, button { font-size: 14px; line-height: 1.5; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; letter-spacing: 0; }

hr { display: none; }

address, caption, cite, code, dfn, em, var { font-style: normal; font-weight: normal; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, main { display: block; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ""; content: none; }

span {margin-right: 0;}

#skipNavi { position: absolute; z-index: 9999; width: 100%; }

#skipNavi a { display: block; overflow: hidden; width: 980px; margin: 0 auto; height: 1px; margin-bottom: -1px; white-space: nowrap; text-align: center; text-indent: -9999px; }

#skipNavi a:focus, #skipNavi a:active { height: 34px; padding: 1px; border-bottom: 2px solid #ebe8e7; background: #666; color: #333; font-weight: bold; font-size: 14px; text-indent: 0; line-height: 33px; }

#skipNavi a:focus span, #skipNavi a:active span { display: block; height: 32px; border: 1px solid #fff; background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#fff), to(#f7f7f7)); background: -ms-linear-gradient(top, #fff, #f7f7f7); }