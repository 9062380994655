

.footer_guide {
    display: flex;
    border-top: 1px solid #e9e9ea;
    border-bottom: 1px solid #e9e9ea;
}

.footer_guide ul li {
    display: inline-block;
}

.footer_guide a {
    display: block;
    padding: .7rem 3rem .7rem 0;
}

.footer_guide a:hover {
    color: inherit;

}

.footer_wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 2.2rem;
    /* color: #f7f7f7; */
    padding: .8rem 0;
}


.footer_txt_box b {
    margin-right: .8rem;
}

.footer_txt_box span {
    display: inline-block;
    position: relative;
}


.copylight {
   line-height: 1rem;
    font-size: 12px;
    color: #b2b2b2;
}




.copylight a {
    color: inherit;
    
}